import { type Session } from "@decentriq/core";
import { useCallback, useMemo } from "react";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import {
  type PublishedDatasetsHashes,
  type RulesBasedAudienceDataAttributes,
  type RulesBasedAudienceDataAttributesFileStructure,
} from "features/mediaDataRoom/models";

interface DataAttributesHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  publishedDatasetsHashes: PublishedDatasetsHashes;
  session?: Session;
  skip: boolean;
}

type DataAttributesHookResult =
  MediaDataRoomJobHookResult<RulesBasedAudienceDataAttributes>;

const useDataAttributes = ({
  dataRoomId,
  driverAttestationHash,
  publishedDatasetsHashes,
  session,
  skip,
}: DataAttributesHookPayload): DataAttributesHookResult => {
  // We might want to extract this to a separate hook, but first we need to understand from what folders we can import what
  const transformDataAttributes = useCallback<
    MediaDataRoomJobResultTransform<RulesBasedAudienceDataAttributes>
  >(async (zip) => {
    const attributesFile = zip.file("attributes.json");
    if (attributesFile === null) {
      throw new Error("attributes.json not found in zip");
    }
    return (
      JSON.parse(
        await attributesFile.async("string")
      ) as RulesBasedAudienceDataAttributesFileStructure
    ).attributes;
  }, []);
  const customDatasetsHashes = useMemo(
    () => publishedDatasetsHashes,
    // Data attributes computation depends only on demographics & segments datasets so only that used for caching
    // TODO: uncomment when issue with jobs resolved
    // .updateAudiencesDatasetHash(null)
    // .updateDatasetsHash(
    //   publishedDatasetsHashes.hasRequiredData
    //     ? {
    //         demographicsDatasetHash:
    //           publishedDatasetsHashes.publisherDatasetsHashes
    //             .demographicsDatasetHash ?? "",
    //         segmentsDatasetHash:
    //           publishedDatasetsHashes.publisherDatasetsHashes
    //             .segmentsDatasetHash ?? "",
    //       }
    //     : null
    // ),
    [publishedDatasetsHashes]
  );
  return useMediaDataRoomJob({
    input: MediaDataRoomJobInput.create(
      "getDataAttributes",
      dataRoomId,
      driverAttestationHash,
      customDatasetsHashes
    ),
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform: transformDataAttributes,
  });
};

export default useDataAttributes;
