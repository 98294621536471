import { useApolloClient } from "@apollo/client";
import { CreateDatasetImportDocument } from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import { Button } from "@mui/joy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import saveAs from "file-saver";
import snakeCase from "lodash/snakeCase";
import * as forge from "node-forge";
import { type SnackbarKey } from "notistack";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore } from "contexts";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import {
  type AudienceSizesHookResult,
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  type MediaDataRoomRequestKey,
  useAudienceSizes,
  useMediaDataRoomJob,
  useMediaDataRoomLazyJob,
  useMediaDataRoomRequest,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type AudiencesFileStructure,
} from "features/mediaDataRoom/models";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useSafeContext,
} from "hooks";
import { delay } from "utils";

export interface AdvertiserAudiencesContextValue {
  audiences: MediaDataRoomJobHookResult<Audience[]>;
  isAudiencesDataOutdated: boolean;
  saveAudience: (audience: Audience) => Promise<void>;
  isSavingAudience: boolean;
  publishAudience: (audience: Audience) => Promise<void>;
  isPublishingAudience: boolean;
  isExportingAudience: Record<string, boolean>;
  downloadAudience: (audience: Audience) => Promise<void>;
  exportAudienceAsDataset: (audience: Audience) => Promise<void>;
  isDeletingAudience: boolean;
  deleteAudience: (audience: Audience) => Promise<void>;
  getAudiencePreqrequisites: (audienceId: string) => Audience[] | undefined;
  audienceSizes: AudienceSizesHookResult;
}

const AdvertiserAudiencesContext =
  createContext<AdvertiserAudiencesContextValue | null>(null);

AdvertiserAudiencesContext.displayName = "AdvertiserAudiencesContext";

export const useAdvertiserAudiences = () =>
  useSafeContext(AdvertiserAudiencesContext);

const AdvertiserAudiencesWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    dataRoomId,
    dataRoomName,
    driverAttestationHash,
    isDeactivated,
    isAdvertiser,
    isObserver,
    isAgency,
  } = useMediaDataRoom();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const apolloClient = useApolloClient();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const { client } = useApiCore();
  const {
    publishedDatasetsHashes,
    session,
    datasets: {
      updateAudiencesDatasetHash,
      updatePublishedDatasetsHashes,
      fetch: fetchDatasets,
      data: { advertiserDatasetHash, publisherDatasetsHashes, hasRequiredData },
    },
  } = useMediaDataRoomInsightsData();
  const [publishAudiencesJson] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key: "publishAudiencesJson",
  });
  const [unpublishAudiencesJson] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key: "unpublishAudiencesJson",
    requestCreator: useCallback(
      (dataRoomIdHex: string) => ({ dataRoomIdHex }),
      []
    ),
  });
  const mediaDataRoomJobInput = useMemo(
    () =>
      MediaDataRoomJobInput.create(
        "getAudiencesForAdvertiser",
        dataRoomId,
        driverAttestationHash,
        publishedDatasetsHashes
      ),
    [dataRoomId, driverAttestationHash, publishedDatasetsHashes]
  );
  const transform = useCallback<
    MediaDataRoomJobResultTransform<{
      // This field is part of the nasty workaround to show when audiences.json is outdated
      // its used to show warning that datasets are outdated and audiences.json needs to be updated but current user is not allowed to do so
      isOutdated?: boolean;
      audiences: Audience[];
    }>
  >(
    async (zip) => {
      const audiencesFile = zip.file("audiences.json");
      if (audiencesFile === null) {
        throw new Error("audiences.json not found in zip");
      }
      const audiencesFileStructure = JSON.parse(
        await audiencesFile.async("string")
      ) as AudiencesFileStructure;
      if (audiencesFileStructure.advertiser_manifest_hash === null) {
        return { audiences: audiencesFileStructure.audiences };
      }
      const hashes = await fetchDatasets();
      if (
        audiencesFileStructure.advertiser_manifest_hash ===
          hashes.advertiserDatasetHash &&
        audiencesFileStructure.matching_manifest_hash ===
          hashes.publisherDatasetsHashes.matchingDatasetHash &&
        audiencesFileStructure.embeddings_manifest_hash ===
          hashes.publisherDatasetsHashes.embeddingsDatasetHash &&
        audiencesFileStructure.demographics_manifest_hash ===
          hashes.publisherDatasetsHashes.demographicsDatasetHash &&
        audiencesFileStructure.segments_manifest_hash ===
          hashes.publisherDatasetsHashes.segmentsDatasetHash
      ) {
        return { audiences: audiencesFileStructure.audiences };
      }
      const canResetAudiences = isAdvertiser || isAgency;
      // This is nasty workaround to force audiences reset when datasets being updated but that is not reflected in audiences.json
      if (canResetAudiences) {
        const input = MediaDataRoomJobInput.create(
          "getAudiencesForAdvertiser",
          dataRoomId,
          driverAttestationHash,
          hashes.updateAudiencesDatasetHash(null)
        );
        await unpublishAudiencesJson({ options: { session } });
        updatePublishedDatasetsHashes(hashes.updateAudiencesDatasetHash(null));
        if (canResetAudiences) {
          void queryClient.refetchQueries({
            queryKey: input.buildQueryKey(),
          });
        }
      }
      return { audiences: [], isOutdated: true };
    },
    [
      fetchDatasets,
      unpublishAudiencesJson,
      updatePublishedDatasetsHashes,
      session,
      queryClient,
      dataRoomId,
      driverAttestationHash,
      isAdvertiser,
      isAgency,
    ]
  );
  const audiencesJobResult = useMediaDataRoomJob({
    input: mediaDataRoomJobInput,
    requestCreator: (dataRoomIdHex, scopeIdHex) => ({
      dataRoomIdHex,
      scopeIdHex,
    }),
    session,
    skip: !(
      Boolean(dataRoomId) &&
      Boolean(driverAttestationHash) &&
      (isAdvertiser || isObserver || isAgency) &&
      !isDeactivated &&
      publishedDatasetsHashes.hasRequiredData
    ),
    transform,
  });
  const setAudiencesCacheData = useCallback(
    (audiences: Audience[] | undefined) =>
      audiencesJobResult.setCacheData(audiences ? { audiences } : undefined),
    [audiencesJobResult]
  );
  const audiences = audiencesJobResult.computeResults?.audiences;
  useEffect(() => {
    if (audiencesJobResult.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          audiencesJobResult.error,
          "Unable to fetch audiences"
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [
    audiencesJobResult.error,
    enqueueSnackbar,
    closeSnackbar,
    setErrorSnackbarId,
  ]);
  const { disableSizeEstimationForAudience, ...restOfAudienceSizes } =
    useAudienceSizes({
      audiences,
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes,
      session,
    });
  const saveAudiencesToFile = useMutation({
    mutationFn: async ({
      nextAudiences,
    }: {
      nextAudiences: Audience[];
      prevAudiences: Audience[];
    }) => {
      setAudiencesCacheData(nextAudiences);
      if (!hasRequiredData) {
        throw new Error("Advertiser dataset not published");
      }
      const activatedAudiencesConfig: AudiencesFileStructure = {
        advertiser_manifest_hash: advertiserDatasetHash,
        audiences: nextAudiences,
        demographics_manifest_hash:
          publisherDatasetsHashes.demographicsDatasetHash,
        embeddings_manifest_hash: publisherDatasetsHashes.embeddingsDatasetHash,
        matching_manifest_hash: publisherDatasetsHashes.matchingDatasetHash,
        segments_manifest_hash: publisherDatasetsHashes.segmentsDatasetHash,
        version: "v1",
      };
      const key = new Key();
      const audiencesJsonHash = await client.uploadDataset(
        new TextEncoder().encode(JSON.stringify(activatedAudiencesConfig)),
        key,
        "audiences.json",
        { isAccessory: true }
      );
      await publishAudiencesJson({
        requestCreator: (dataRoomIdHex, scopeIdHex) => ({
          dataRoomIdHex,
          datasetHashHex: audiencesJsonHash,
          encryptionKeyHex: forge.util.binary.hex.encode(key.material),
          scopeIdHex,
        }),
      });
      updateAudiencesDatasetHash(audiencesJsonHash);
    },
    onError: (_, { prevAudiences }) => {
      setAudiencesCacheData(prevAudiences);
    },
  });
  const saveAudience = useCallback(
    async (audience: Audience) =>
      saveAudiencesToFile.mutateAsync({
        nextAudiences: [audience, ...(audiences ?? [])],
        prevAudiences: audiences ?? [],
      }),
    [audiences, saveAudiencesToFile]
  );
  const getAudiencePreqrequisites = useCallback(
    (audienceId: string): Audience[] | undefined => {
      const currentAudiences = audiences ?? [];
      return session?.compiler.abMedia
        .getAudiencePreqrequisites(audienceId, currentAudiences)
        .map((id) => currentAudiences.find((a) => a.id === id))
        .filter(Boolean) as Audience[];
    },
    [audiences, session]
  );
  const deleteAudience = useCallback(
    async (audience: Audience) => {
      try {
        const currentAudiences = audiences ?? [];
        const dependencies = getAudiencePreqrequisites(audience.id);
        const nextAudiences: Audience[] = currentAudiences.filter(
          (a) =>
            a.id !== audience.id && !dependencies?.some(({ id }) => id === a.id)
        );
        await saveAudiencesToFile.mutateAsync({
          nextAudiences,
          prevAudiences: currentAudiences,
        });
        disableSizeEstimationForAudience([
          audience.id,
          ...(dependencies?.map(({ id }) => id) ?? []),
        ]);
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Failed to delete audience."
          )
        );
      }
    },
    [
      audiences,
      enqueueSnackbar,
      saveAudiencesToFile,
      getAudiencePreqrequisites,
      disableSizeEstimationForAudience,
    ]
  );
  const publishAudience = useCallback(
    async (audience: Audience) => {
      try {
        const currentAudiences = audiences ?? [];
        const dependencyIds = session!.compiler.abMedia.getAudienceDependencies(
          audience.id,
          currentAudiences
        );
        const nextAudiences: Audience[] = currentAudiences.map((a) =>
          a.id === audience.id
            ? { ...a, mutable: { ...a.mutable, status: "published" } }
            : dependencyIds.includes(a.id) && a.mutable.status !== "published"
              ? {
                  ...a,
                  mutable: {
                    ...a.mutable,
                    status: "published_as_intermediate",
                  },
                }
              : a
        );
        await delay(1000);
        await saveAudiencesToFile.mutateAsync({
          nextAudiences,
          prevAudiences: currentAudiences,
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Failed to publish audience."
          )
        );
      }
    },
    [audiences, enqueueSnackbar, saveAudiencesToFile, session]
  );
  const [isExportingAudience, setIsExportingAudience] = useState<
    Record<string, boolean>
  >({});
  const transformAudienceForDownload = useCallback<
    MediaDataRoomJobResultTransform<string>
  >(async (zip) => {
    const audienceUsersFile = zip.file("audience_users.csv");
    if (audienceUsersFile === null) {
      throw new Error("audience_users.csv not found in zip");
    }
    const audienceUsersCsv = await audienceUsersFile.async("string");
    if (!audienceUsersCsv) {
      throw new Error("Audience is empty");
    }
    return audienceUsersCsv;
  }, []);
  const [getAudienceUserListForAdvertiserLal] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "getAudienceUserListForAdvertiserLal",
      dataRoomId,
      driverAttestationHash
    ),
    session,
    transform: transformAudienceForDownload,
  });
  const [getAudienceUserListForAdvertiser] = useMediaDataRoomLazyJob({
    input: MediaDataRoomJobInput.create(
      "getAudienceUserListForAdvertiser",
      dataRoomId,
      driverAttestationHash
    ),
    session,
    transform: transformAudienceForDownload,
  });
  const downloadAudience = useCallback(
    async (audience: Audience) => {
      try {
        if (!publishedDatasetsHashes.hasRequiredData) {
          throw new Error(
            "Getting audience requires both the publisher and advertiser dataset uploaded and non-empty activated audiences config published"
          );
        }
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: true,
        }));
        const payloadParams = session!.compiler.abMedia.getParameterPayloads(
          audience.id,
          audiences || []
        );
        let fileContent;
        if (payloadParams?.lal) {
          fileContent = await getAudienceUserListForAdvertiserLal({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: payloadParams.generate,
              lalAudience: payloadParams.lal!,
              scopeIdHex,
            }),
            updateInput: (input) => input.withResourceId(audience.id),
          });
        } else {
          fileContent = await getAudienceUserListForAdvertiser({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: payloadParams.generate,
              scopeIdHex,
            }),
            updateInput: (input) => input.withResourceId(audience.id),
          });
        }
        const filename = `${dataRoomName}-${audience.mutable.name}.csv`;
        const file = new File([fileContent], filename, {
          type: "application/octet-stream;charset=utf-8",
        });
        saveAs(file);
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Unable to download audience"
          )
        );
      } finally {
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: false,
        }));
      }
    },
    [
      session,
      dataRoomName,
      audiences,
      enqueueSnackbar,
      publishedDatasetsHashes.hasRequiredData,
      getAudienceUserListForAdvertiserLal,
      getAudienceUserListForAdvertiser,
    ]
  );
  const exportAudienceAsDataset = useCallback(
    async (audience: Audience) => {
      try {
        if (!publishedDatasetsHashes.hasRequiredData) {
          throw new Error(
            "Storing an audience as dataset requires both the publisher and advertiser dataset uploaded"
          );
        }
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: true,
        }));
        const payloadParams = session!.compiler.abMedia.getParameterPayloads(
          audience.id,
          audiences || []
        );
        const isLalAudience = Boolean(payloadParams.lal);
        const computeNodeId: MediaDataRoomRequestKey = isLalAudience
          ? "getAudienceUserListForAdvertiserLal"
          : "getAudienceUserListForAdvertiser";
        const filename = `${dataRoomName}-${audience.mutable.name}.csv`;
        const parameters: { computeNodeName: string; content: string }[] = [
          {
            computeNodeName: "generate_audience.json",
            content: JSON.stringify(payloadParams.generate),
          },
        ];
        if (isLalAudience) {
          parameters.push({
            computeNodeName: "lal_audience.json",
            content: JSON.stringify(payloadParams.lal),
          });
        }
        await apolloClient.mutate({
          mutation: CreateDatasetImportDocument,
          variables: {
            input: {
              compute: {
                computeNodeId: snakeCase(computeNodeId),
                dataRoomId,
                driverAttestationHash,
                importFileWithName: "audience_users.csv",
                isHighLevelNode: false,
                parameters,
                renameFileTo: filename,
                shouldImportAllFiles: false,
                shouldImportAsRaw: false,
              },
              datasetName: filename,
            },
          },
        });
        enqueueSnackbar(
          `"${audience.mutable.name}" result is being stored. Please check the status in the 'Datasets' page.`,
          {
            action: (
              <Button onClick={() => navigate("/datasets/external")}>
                Go to Datasets
              </Button>
            ),
          }
        );
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(error, "Unable to export audience")
        );
      } finally {
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: false,
        }));
      }
    },
    [
      navigate,
      enqueueSnackbar,
      dataRoomName,
      publishedDatasetsHashes.hasRequiredData,
      session,
      apolloClient,
      audiences,
      dataRoomId,
      driverAttestationHash,
    ]
  );
  const contextValue = useMemo<AdvertiserAudiencesContextValue>(
    () => ({
      audienceSizes: {
        disableSizeEstimationForAudience,
        ...restOfAudienceSizes,
      },
      // This override is needed to make same interface and do no expose the isOutdated field to the consumers of this context;
      // it should be removed as soon as we get rid of the nasty workaround for outdated audiences.json
      audiences: {
        ...(audiencesJobResult as MediaDataRoomJobHookResult<unknown> as MediaDataRoomJobHookResult<
          Audience[]
        >),
        computeResults: audiences,
        setCacheData: setAudiencesCacheData,
      },
      deleteAudience,
      downloadAudience,
      exportAudienceAsDataset,
      getAudiencePreqrequisites,
      isAudiencesDataOutdated:
        audiencesJobResult.computeResults?.isOutdated ?? false,
      isDeletingAudience: saveAudiencesToFile.isPending,
      isExportingAudience,
      isPublishingAudience: saveAudiencesToFile.isPending,
      isSavingAudience: saveAudiencesToFile.isPending,
      publishAudience,
      refetchAudiences: audiencesJobResult.retry,
      saveAudience,
      viewAudiencesError: audiencesJobResult.error,
    }),
    [
      audiences,
      audiencesJobResult,
      deleteAudience,
      getAudiencePreqrequisites,
      saveAudiencesToFile.isPending,
      publishAudience,
      saveAudience,
      downloadAudience,
      exportAudienceAsDataset,
      isExportingAudience,
      disableSizeEstimationForAudience,
      restOfAudienceSizes,
      setAudiencesCacheData,
    ]
  );
  return (
    <AdvertiserAudiencesContext.Provider value={contextValue}>
      {children}
    </AdvertiserAudiencesContext.Provider>
  );
};

export default AdvertiserAudiencesWrapper;
