import { type ComponentType } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useOrganizationPreferences, useUserRole } from "hooks";

const AdminRoute = ({
  component: Component,
  ...props
}: {
  component: ComponentType;
}) => {
  const navigate = useNavigate();
  const {
    isOrganizationAdmin,
    isNormalUser,
    organizationId,
    isLoading,
    isSuperAdmin,
    isSuperAdminReadOnly,
  } = useUserRole();
  const {
    isSubscribedState,
    isSuspendedState,
    isLoading: isOrganizationPreferencesLoading,
  } = useOrganizationPreferences();
  const homeUrl = "/";
  const adminUrl = "/admin";
  const organizationsUrl = "/admin/organizations";
  const organizationIdParamUrl = `${organizationsUrl}/:organizationId`;
  const organizationIdUrl = `${organizationsUrl}/${organizationId}`;
  const matchesOrganizationIdAnywhere = useMatch({
    end: false,
    path: organizationIdParamUrl,
  });
  const matchesAdminExactly = useMatch({
    end: true,
    path: adminUrl,
  });
  if (
    !(isLoading || isOrganizationPreferencesLoading) &&
    (isNormalUser || !(isSubscribedState || isSuspendedState))
  ) {
    navigate(homeUrl);
  }
  if (
    !(isLoading || isOrganizationPreferencesLoading) &&
    (isSuperAdmin || isSuperAdminReadOnly) &&
    matchesAdminExactly
  ) {
    navigate(organizationsUrl);
  }
  if (
    !(isLoading || isOrganizationPreferencesLoading) &&
    isOrganizationAdmin &&
    matchesOrganizationIdAnywhere?.params?.organizationId !== organizationId
  ) {
    navigate(organizationIdUrl);
  }
  return !(isLoading || isOrganizationPreferencesLoading) ? (
    <Component {...props} />
  ) : null;
};

export default AdminRoute;
