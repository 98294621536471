import { type ComponentType } from "react";
import { withKeychain } from "wrappers";

// Note: This routewrapper can be completely removed once the keychain migration is complete.
// (expected date is February 2025)
const PrivateRoute = ({
  component,
  ...props
}: {
  component: ComponentType;
}) => {
  const KeychainWrapped = withKeychain(component);
  return <KeychainWrapped {...props} />;
};

export default PrivateRoute;
