import { DqTable } from "@decentriq/components";
import { type MRT_ColumnDef } from "material-react-table";
import { memo } from "react";
import {
  dataRoomTablePrimitiveTypePresentation,
  legacyDataRoomTablePrimitiveTypeToColumnDataType,
} from "models";
import { type TableScheme } from "utils/apicore";

interface ComputeNodeSchemaResultTableProps {
  schema: TableScheme | undefined;
}

const ComputeNodeSchemaResultTable = memo<ComputeNodeSchemaResultTableProps>(
  ({ schema }) => {
    const columnsDefinition: MRT_ColumnDef<
      TableScheme["namedColumns"][number]
    >[] = [
      {
        accessorKey: "name",
        header: "Column name",
        id: "name",
      },
      {
        Cell: ({ cell }) => {
          const columnType =
            cell.getValue<TableScheme["namedColumns"][number]["columnType"]>();
          if (!columnType?.primitiveType) {
            return "Unknown type";
          }
          return dataRoomTablePrimitiveTypePresentation.get(
            legacyDataRoomTablePrimitiveTypeToColumnDataType.get(
              columnType.primitiveType
            )!
          );
        },
        accessorKey: "columnType",
        header: "Data type",
        id: "primitiveType",
      },
    ];
    return (
      <DqTable columns={columnsDefinition} data={schema?.namedColumns ?? []} />
    );
  }
);
ComputeNodeSchemaResultTable.displayName = "ComputeNodeSchemaResultTable";

export default ComputeNodeSchemaResultTable;
