import {
  type Client,
  Keychain as CoreKeychain,
  KeychainDecryptError,
  type KeychainEntry,
} from "@decentriq/core";
import { type KeychainEntryKind } from "@decentriq/core/dist/keychain";
import { KeychainDerivationApi } from "@decentriq/keychain-derivation";
import * as forge from "node-forge";
import {
  type ChangeKeychainPasswordPayload,
  type EmptyKeychainPayload,
  type Keychain,
  type KeychainItem,
  KeychainItemKind,
  KeychainOperationErrorKind,
  type ResetKeychainPayload,
  type UnlockKeychainPayload,
  UserKeychainStatus,
} from "./types";

export function itemToEntry(item: KeychainItem): KeychainEntry {
  switch (item.kind) {
    case KeychainItemKind.Dataset:
      return {
        key: item.id,
        kind: "dataset_key",
        value: forge.util.binary.hex.decode(item.value),
      };
    case KeychainItemKind.DatasetMetadata:
      return {
        key: item.id,
        kind: "dataset_metadata",
        value: new TextEncoder().encode(item.value),
      };
    case KeychainItemKind.PendingDatasetImport:
      return {
        key: item.id,
        kind: "pending_dataset_import",
        value: forge.util.binary.hex.decode(item.value),
      };
    default:
      throw new Error(`Unsupported KeychainItem kind: ${item.kind}`);
  }
}

const itemKindToEntryKind: Record<KeychainItemKind, KeychainEntryKind> = {
  [KeychainItemKind.Dataset]: "dataset_key",
  [KeychainItemKind.PendingDatasetImport]: "pending_dataset_import",
  [KeychainItemKind.DatasetMetadata]: "dataset_metadata",
};

const entryToItem = (keychainEntry: KeychainEntry): KeychainItem => {
  switch (keychainEntry.kind) {
    case "dataset_key":
      return {
        id: keychainEntry.key,
        kind: KeychainItemKind.Dataset,
        value: forge.util.binary.hex.encode(keychainEntry.value),
      };
    case "dataset_metadata":
      return {
        id: keychainEntry.key,
        kind: KeychainItemKind.DatasetMetadata,
        value: new TextDecoder().decode(keychainEntry.value),
      };
    case "pending_dataset_import":
      return {
        id: keychainEntry.key,
        kind: KeychainItemKind.PendingDatasetImport,
        value: forge.util.binary.hex.encode(keychainEntry.value),
      };
    default:
      throw new Error("Keychain item kind is not supported");
  }
};

const keychainDerivationWorker = new Promise<Worker>((resolve, reject) => {
  const worker = new Worker(new URL("./worker.ts", import.meta.url), {
    type: "module",
  });
  const timeoutMs = 20000;
  const timeoutId = setTimeout(() => {
    reject(
      `keychain-derivation worker did not respond within ${
        timeoutMs / 1000
      } seconds`
    );
  }, timeoutMs);
  worker.addEventListener("message", (message) => {
    if (message.data === "hello") {
      clearTimeout(timeoutId);
      resolve(worker);
    }
  });
});

let keychainDerivationApi: KeychainDerivationApi | undefined = undefined;

const getKeychainDerivationApi = async (): Promise<KeychainDerivationApi> => {
  if (!keychainDerivationApi) {
    keychainDerivationApi = new KeychainDerivationApi(
      await keychainDerivationWorker
    );
  }
  return keychainDerivationApi;
};

function encodePassword(password: string): Uint8Array {
  return new TextEncoder().encode(password);
}

const retrieveSubtleWrappingKey = async (
  client: Client
): Promise<CryptoKey> => {
  const wrappingKeyMaterial = await client.getLocalStorageWrappingKey();
  return window.crypto.subtle.importKey(
    "raw",
    wrappingKeyMaterial,
    { length: 256, name: "AES-KW" },
    false,
    ["wrapKey", "unwrapKey"]
  );
};

export const localStorageKeychainKeyId = "dq:wrapped-keychain-master-key";

const retrieveLocalMasterKey = (): Uint8Array | undefined => {
  const storedJson = window.localStorage.getItem(localStorageKeychainKeyId);
  if (!storedJson) {
    return undefined;
  }
  const stored: StoredWrappedKey = JSON.parse(storedJson);
  const now = new Date();

  if (now.getTime() > stored.expiryMillis) {
    window.localStorage.removeItem(localStorageKeychainKeyId);
    return undefined;
  }

  return forge.util.binary.hex.decode(stored.wrappedKey);
};

const storeMasterKey = async (
  client: Client,
  kc: CoreKeychain
): Promise<void> => {
  const wrappingKey = await retrieveSubtleWrappingKey(client);
  const subtleMasterKey = await window.crypto.subtle.importKey(
    "raw",
    kc.getMasterKey(),
    "AES-GCM",
    true,
    ["encrypt"]
  );
  const wrappedMasterKey = new Uint8Array(
    await window.crypto.subtle.wrapKey(
      "raw",
      subtleMasterKey,
      wrappingKey,
      "AES-KW"
    )
  );
  const expiry = new Date();
  expiry.setDate(expiry.getDate() + 30);
  const stored: StoredWrappedKey = {
    expiryMillis: expiry.getTime(),
    wrappedKey: forge.util.binary.hex.encode(wrappedMasterKey),
  };

  window.localStorage.setItem(
    localStorageKeychainKeyId,
    JSON.stringify(stored)
  );
  return;
};

type StoredWrappedKey = {
  expiryMillis: number;
  wrappedKey: string;
};

const deriveMasterKeyWithWebWorker = async (
  client: Client,
  password: string
): Promise<Uint8Array> => {
  const { keychainConfiguration } = await client.getKeychainConfiguration();
  if (!keychainConfiguration) {
    throw new Error("Keychain instance does not exist");
  }
  const salt = keychainConfiguration.salt;
  const api = await getKeychainDerivationApi();
  const response = await api.deriveMasterKey({
    password: encodePassword(password),
    salt,
  });
  if (response.result.kind === "error") {
    throw new Error(`Cannot unlock Keychain: ${response.result.error}`);
  }
  return response.result.success;
};

type ListenerCallback = (() => void) | (() => Promise<void>);

interface IClientSideKeychain extends Keychain {
  unlock(password: string): Promise<UnlockKeychainPayload>;
  create(password: string): Promise<void>;
  empty(password: string): Promise<EmptyKeychainPayload>;
  reset(): Promise<ResetKeychainPayload>;
  changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<ChangeKeychainPasswordPayload>;
  initializeLocalKeychain(): Promise<void>;
  isInitialized(): boolean;
}

export class ClientSideKeychain implements IClientSideKeychain {
  private keychain: CoreKeychain | undefined;
  private client: Client;
  private listeners: Set<ListenerCallback>;

  constructor(client: Client) {
    this.client = client;
    this.listeners = new Set();
  }

  async create(password: string): Promise<void> {
    const api = await getKeychainDerivationApi();
    const response = await api.initMasterKey({
      password: encodePassword(password),
    });
    if (response.result.kind === "error") {
      throw new Error(`Cannot create Keychain: ${response.result.error}`);
    }
    const kc = await CoreKeychain.createNewKeychainWithMasterKey(
      this.client,
      response.result.success.masterKey,
      response.result.success.salt
    );
    if (!kc) {
      throw new Error(`Cannot create Keychain: keychain already exists`);
    }
    await this.setLocalKeychain(kc);
  }

  async unlock(password: string): Promise<UnlockKeychainPayload> {
    const masterKey = await deriveMasterKeyWithWebWorker(this.client, password);
    try {
      const kc = await CoreKeychain.initWithMasterKey(this.client, masterKey);
      await this.setLocalKeychain(kc);
    } catch (error) {
      if (error instanceof KeychainDecryptError) {
        return {
          error: {
            kind: KeychainOperationErrorKind.WrongPassword,
            message: "Wrong Keychain password",
          },
        };
      } else {
        throw error;
      }
    }
    return {};
  }

  async insertItem(keychainItem: KeychainItem): Promise<KeychainItem> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    const keychainEntry = itemToEntry(keychainItem);
    await this.keychain.insert(keychainEntry);
    this.notifyListeners();
    return entryToItem(keychainEntry);
  }

  async insertItems(items: KeychainItem[]): Promise<KeychainItem[]> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    await this.keychain.insertMany(items.map(itemToEntry));
    this.notifyListeners();
    return items;
  }

  async removeItem({
    id,
    kind,
  }: Omit<KeychainItem, "value">): Promise<boolean> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    let success: boolean;
    switch (kind) {
      case KeychainItemKind.Dataset:
        success = await this.keychain.remove("dataset_key", id);
        break;
      case KeychainItemKind.DatasetMetadata:
        success = await this.keychain.remove("dataset_metadata", id);
        break;
      case KeychainItemKind.PendingDatasetImport:
        success = await this.keychain.remove("pending_dataset_import", id);
        break;
      default:
        throw new Error(`Unsupported KeychainItem kind: ${kind}`);
    }
    this.notifyListeners();
    return success;
  }

  async removeItems(items: Omit<KeychainItem, "value">[]): Promise<void> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    await this.keychain.removeMany(
      items.map(({ kind, id: key }) => ({
        key,
        kind: itemKindToEntryKind[kind],
      }))
    );
    this.notifyListeners();
  }

  async reset(): Promise<ResetKeychainPayload> {
    await CoreKeychain.reset(this.client);
    await this.deleteLocalKeychain();
    this.notifyListeners();
    return {};
  }

  async empty(password: string): Promise<EmptyKeychainPayload> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    // Check old password
    const masterKey = await deriveMasterKeyWithWebWorker(this.client, password);
    try {
      const kc = await CoreKeychain.initWithMasterKey(this.client, masterKey);
      await kc.clear();
    } catch (error) {
      if (error instanceof KeychainDecryptError) {
        return {
          error: {
            kind: KeychainOperationErrorKind.WrongPassword,
            message: "Wrong Keychain password",
          },
        };
      } else {
        throw error;
      }
    }
    this.notifyListeners();
    return {};
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<ChangeKeychainPasswordPayload> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    // Check old password
    const oldMasterKey = await deriveMasterKeyWithWebWorker(
      this.client,
      oldPassword
    );
    try {
      await CoreKeychain.initWithMasterKey(this.client, oldMasterKey);
      const newMasterKey = await deriveMasterKeyWithWebWorker(
        this.client,
        newPassword
      );
      // Change password
      await this.keychain.changeMasterKey(newMasterKey);
      const kc = await CoreKeychain.initWithMasterKey(
        this.client,
        newMasterKey
      );
      await this.setLocalKeychain(kc);
      return {};
    } catch (err) {
      if (err instanceof KeychainDecryptError) {
        return {
          error: {
            kind: KeychainOperationErrorKind.WrongPassword,
            message: "Wrong Keychain password",
          },
        };
      } else {
        throw new Error(`Something went wrong: ${err}`);
      }
    }
  }

  async getItem(id: string, kind: KeychainItemKind): Promise<KeychainItem> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    let keychainEntry = undefined;
    switch (kind) {
      case KeychainItemKind.Dataset:
        keychainEntry = await this.keychain.get("dataset_key", id);
        break;
      case KeychainItemKind.DatasetMetadata:
        keychainEntry = await this.keychain.get("dataset_metadata", id);
        break;
      case KeychainItemKind.PendingDatasetImport:
        keychainEntry = await this.keychain.get("pending_dataset_import", id);
        break;
    }
    if (!keychainEntry) {
      throw new Error(`Invalid Keychain id: ${id} with kind: ${kind}`);
    }
    return entryToItem(keychainEntry);
  }

  async getItems(): Promise<KeychainItem[]> {
    if (!this.keychain) {
      throw new Error("Keychain is not initialized");
    }
    const keychainEntries = await this.keychain.items();
    const items = keychainEntries.map(entryToItem);
    return items;
  }

  async statusOrInitialize(): Promise<UserKeychainStatus> {
    const { keychainConfiguration } =
      await this.client.getKeychainConfiguration();
    if (!keychainConfiguration) {
      return UserKeychainStatus.Unset;
    }
    await this.initializeLocalKeychain();
    if (this.keychain) {
      return UserKeychainStatus.Unlocked;
    }
    return UserKeychainStatus.Locked;
  }

  async initializeLocalKeychain(): Promise<void> {
    if (this.keychain) {
      return;
    }
    const wrappedMasterKey = retrieveLocalMasterKey();
    if (!wrappedMasterKey) {
      return;
    }
    const wrappingKey = await retrieveSubtleWrappingKey(this.client);
    const subtleMasterKey = await window.crypto.subtle.unwrapKey(
      "raw",
      wrappedMasterKey,
      wrappingKey,
      "AES-KW",
      "AES-GCM", // We set it as "AES-GCM", however, the masterKey algorithm is not necessarily "AES-GCM".
      // This is fine because we don't use it with the WebCrypto API, but export it right away.
      true, // extractable key
      ["encrypt"] // This key shouldn't be used for anything except exporting, but this can't be empty
    );

    const masterKey = new Uint8Array(
      await window.crypto.subtle.exportKey("raw", subtleMasterKey)
    );
    this.keychain = await CoreKeychain.initWithMasterKey(
      this.client,
      masterKey
    );
    return;
  }

  isInitialized(): boolean {
    return this.keychain !== undefined;
  }

  registerListener(callback: ListenerCallback): () => void {
    this.listeners.add(callback);
    return () => this.listeners.delete(callback);
  }

  private notifyListeners() {
    for (const callback of this.listeners) {
      callback();
    }
  }

  private async setLocalKeychain(kc: CoreKeychain): Promise<void> {
    await storeMasterKey(this.client, kc);
    this.keychain = kc;
    return;
  }

  private async deleteLocalKeychain() {
    this.keychain = undefined;
    window.localStorage.removeItem(localStorageKeychainKeyId);
    return;
  }
}
