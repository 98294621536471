import { useApolloClient } from "@apollo/client";
import { DqLoader } from "@decentriq/components";
import { useFetchComputeJobResultMutation } from "@decentriq/graphql/dist/hooks";
import {
  CompletePublishedDataRoomDocument,
  ComputeJobAutoFetching,
  type PublishedNodesTypenamesQuery,
  PublishedPreviewNodeQuotasFragment,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import { exceptions } from "@decentriq/utils";
import { Box } from "@mui/joy";
import { memo, useEffect, useState } from "react";
import { useApiCore, usePublishedDataRoom } from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { PublishedComputeNodeTypeNames } from "models";
import { decodeComputationResult, type TableScheme } from "utils/apicore";
import ComputeNodeDataResultTable from "./ComputeNodeDataResultTable";
import ComputeNodeSchemaResultTable from "./ComputeNodeSchemaResultTable";

interface ComputeNodeResultProps {
  computeNodeId: string;
  jobId: string;
  computationTypename: WorkerTypeShortName;
  autoFetching: ComputeJobAutoFetching;
  dcrHash?: string;
  driverAttestationHash?: string;
}

type ComputeNodeResultPreviewProps = Omit<
  ComputeNodeResultProps,
  "autoFetching"
>;

const ComputeNodeResultPreview = memo<ComputeNodeResultPreviewProps>(
  ({ jobId, computationTypename, computeNodeId, ...restOfProps }) => {
    const [result, setResult] = useState<Uint8Array | undefined>();
    const publishedDcrContextValue = usePublishedDataRoom();
    const { cache } = useApolloClient();
    const { sessionManager } = useApiCore();
    useEffect(
      () => setResult(undefined),
      [setResult, publishedDcrContextValue.testing]
    );
    const dcrHash = restOfProps.dcrHash || publishedDcrContextValue.dcrHash;
    const driverAttestationHash =
      restOfProps.driverAttestationHash ||
      publishedDcrContextValue.driverAttestationHash;
    const { store } = useApiCore();
    const { enqueueSnackbar: enqueueDataRoomSnackbarNoReport } =
      useDataRoomSnackbar({ withReportOnError: false });
    const { enqueueSnackbar: enqueueDataRoomSnackbar } = useDataRoomSnackbar();
    const [fetchResult, { loading }] = useFetchComputeJobResultMutation();
    useEffect(() => {
      fetchResult({
        variables: {
          input: {
            computeNodeId,
            dcrHash,
            driverAttestationHash,
            jobId,
          },
        },
      })
        .then(({ data }) => {
          const storeRef = data?.retrieveComputeJobResult?.result;
          if (!storeRef) {
            return;
          }
          setResult(store.get<Uint8Array>(storeRef));
        })
        .then(async () => {
          const data = cache.readQuery<PublishedNodesTypenamesQuery>({
            query: CompletePublishedDataRoomDocument,
            variables: { id: dcrHash },
          });
          if (
            !data?.publishedDataRoom ||
            !data.publishedDataRoom.publishedNodes.some(
              ({ __typename }) =>
                __typename ===
                PublishedComputeNodeTypeNames.PublishedPreviewNode
            )
          ) {
            return;
          }
          const sdkSession = await sessionManager.get({
            driverAttestationHash,
          });
          const airlockQuotas =
            await sdkSession.retrieveUsedAirlockQuotas(dcrHash);
          data.publishedDataRoom.publishedNodes
            .filter(
              ({ __typename, id }) =>
                __typename ===
                  PublishedComputeNodeTypeNames.PublishedPreviewNode &&
                airlockQuotas.has(id)
            )
            .forEach(({ id }) => {
              const airlockQuota = airlockQuotas.get(id);
              const { limit = 0, used = 0 } = airlockQuota!;
              cache.writeFragment<PublishedPreviewNodeQuotasFragment>({
                data: {
                  id,
                  remainingQuotaBytes: limit - used,
                  totalQuotaBytes: limit,
                  usedQuotaBytes: used,
                },
                fragment: PublishedPreviewNodeQuotasFragment,
                id: cache.identify({
                  __typename:
                    PublishedComputeNodeTypeNames.PublishedPreviewNode,
                  commitId: null,
                  dcrHash,
                  driverAttestationHash,
                  id,
                }),
              });
            });
        })
        .catch((error) => {
          if (error instanceof exceptions.DataRoomComputationOutOfQuotaError) {
            enqueueDataRoomSnackbarNoReport(
              "Airlock limit for this node has been reached",
              { persist: true, variant: "error" }
            );
          } else {
            enqueueDataRoomSnackbar(
              ...mapErrorToGeneralSnackbar(error, "Failed to get result")
            );
          }
        });
    }, [
      computeNodeId,
      dcrHash,
      driverAttestationHash,
      store,
      fetchResult,
      enqueueDataRoomSnackbarNoReport,
      enqueueDataRoomSnackbar,
      jobId,
      sessionManager,
      cache,
    ]);
    if (loading) {
      return (
        <DqLoader
          label="Retrieving computation results"
          sx={{ justifyContent: "center", p: 1, width: "100%" }}
        />
      );
    }
    if (!result) {
      return null;
    }
    return (
      <ComputeNodeDataResultTable
        bytes={result}
        computationTypename={computationTypename || "sql"}
        computeNodeId={computeNodeId}
        hasRunComputation={true}
        isLoading={false}
      />
    );
  }
);
ComputeNodeResultPreview.displayName = "ComputeNodeResultPreview";

type ComputeNodeSchemaPreviewProps = Omit<
  ComputeNodeResultProps,
  "autoFetching" | "computationTypename"
>;

const ComputeNodeSchemaPreview = memo<ComputeNodeSchemaPreviewProps>(
  ({ jobId, computeNodeId, dcrHash, driverAttestationHash }) => {
    const [schema, setSchema] = useState<TableScheme | undefined>();
    const publishedDcr = usePublishedDataRoom();
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const { store } = useApiCore();
    const [fetchResult, { loading }] = useFetchComputeJobResultMutation();
    useEffect(() => {
      fetchResult({
        variables: {
          input: {
            computeNodeId,
            dcrHash: dcrHash || publishedDcr.dcrHash,
            driverAttestationHash:
              driverAttestationHash || publishedDcr.driverAttestationHash,
            jobId,
          },
        },
      })
        .then(async ({ data }) => {
          const storeRef = data?.retrieveComputeJobResult?.result;
          if (storeRef) {
            const schema = store.get<Uint8Array>(storeRef)!;
            const tableSchema = (await decodeComputationResult(schema))[1];
            setSchema(tableSchema);
          }
        })
        .catch((error) => {
          enqueueSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Failed to get result")
          );
        });
    }, [
      computeNodeId,
      dcrHash,
      driverAttestationHash,
      store,
      fetchResult,
      enqueueSnackbar,
      jobId,
      publishedDcr,
    ]);
    if (loading) {
      return (
        <DqLoader
          label="Retrieving computation results"
          sx={{ justifyContent: "center", p: 1, width: "100%" }}
        />
      );
    }
    if (!schema?.namedColumns.length) {
      return null;
    }
    return (
      <Box data-testid="computation_results" mt={1}>
        <ComputeNodeSchemaResultTable schema={schema} />
      </Box>
    );
  }
);
ComputeNodeSchemaPreview.displayName = "ComputeNodeSchemaPreview";

const ComputeNodeResult = memo<ComputeNodeResultProps>(
  ({ autoFetching, ...rest }) => {
    switch (autoFetching) {
      case ComputeJobAutoFetching.Result:
      case ComputeJobAutoFetching.TestResult:
      case ComputeJobAutoFetching.AllTests:
        return <ComputeNodeResultPreview {...rest} />;
      case ComputeJobAutoFetching.Validation:
      case ComputeJobAutoFetching.AllValidations:
        return rest.computationTypename === WorkerTypeShortName.Python ||
          rest.computationTypename === WorkerTypeShortName.R ? null : (
          <ComputeNodeSchemaPreview {...rest} />
        );
      default:
        return null;
    }
  }
);
ComputeNodeResult.displayName = "ComputeNodeResult";

export default ComputeNodeResult;
