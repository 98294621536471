import { Box, Typography } from "@mui/joy";
import { memo, useEffect, useState } from "react";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { getMatchNodeResult, type TableScheme } from "utils/apicore";
import ComputeNodeSchemaResultTable from "../ComputeNodeSchemaResultTable";
import ResultStatistics from "./ResultStatistics";

export interface MatchingNodeResultProps {
  id: string;
  bytes: Uint8Array;
}

const MatchingNodeResult = memo<MatchingNodeResultProps>(({ id, bytes }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [result, setResult] = useState<{
    data?: unknown;
    schema?: TableScheme;
  }>({});
  useEffect(() => {
    getMatchNodeResult(bytes)
      .then(setResult)
      .catch((error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, "Failed to get result")
        );
      });
  }, [bytes, enqueueSnackbar]);
  const { data, schema } = result;
  return result ? (
    <Box sx={{ mt: 1 }}>
      <Typography level="title-md">Statistics</Typography>
      {data ? (
        <Box sx={{ mt: 1 }}>
          <ResultStatistics data={data} id={id} />
        </Box>
      ) : null}
      <Typography level="title-md" sx={{ mt: 3.5 }}>
        Output schema
      </Typography>
      {schema ? <ComputeNodeSchemaResultTable schema={schema} /> : null}
    </Box>
  ) : null;
});
MatchingNodeResult.displayName = "MatchingNodeResult";

export default MatchingNodeResult;
