import { Alert, Box, Link, Typography } from "@mui/joy";
import { useDocsLink } from "hooks";
import AccessTokensConstructor from "./AccessTokensConstructor";

const AccessTokens: React.FC = () => {
  const docsBaseLink = useDocsLink();
  return (
    <Box
      sx={({ spacing }) => ({ margin: spacing(2, 2.5), overflow: "hidden" })}
    >
      <Typography level="title-md" marginBottom={1}>
        API tokens
      </Typography>
      <Alert>
        <Typography level="body-sm" sx={{ color: "inherit" }}>
          The access tokens created on this page can be used in our SDKs to
          authenticate with the Decentriq Platform. For more details, please
          refer{" "}
          <Link
            color="primary"
            href={`${docsBaseLink}/sdk/getting-started`}
            rel="noreferrer"
            target="_blank"
            underline="hover"
          >
            <strong>to the Python SDK documentation</strong>
          </Link>
          . If you are looking for previously created tokens, please refer to
          the{" "}
          <Link color="primary" href="/legacy-tokens" underline="hover">
            <strong>old API tokens page</strong>
          </Link>
          .
        </Typography>
      </Alert>
      <AccessTokensConstructor />
    </Box>
  );
};

export default AccessTokens;
