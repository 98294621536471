import { createContext, memo, useMemo } from "react";
import {
  useDataAttributes,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { useSafeContext } from "hooks";
import useLookalikeAudiencesStatisticPrefetching from "./useLookalikeAudiencesStatisticPrefetching";
import usePublisherAudiencesSizePrefetching from "./usePublisherAudiencesSizePrefetching";

interface AudiencesDataPrefetchingContextValue {
  isDataPrefetching: boolean;
}

const AudiencesDataPrefetchingContext =
  createContext<AudiencesDataPrefetchingContextValue | null>(null);

export const useAudiencesDataPrefetching = () =>
  useSafeContext(AudiencesDataPrefetchingContext);

const AudiencesDataPrefetchingWrapper = memo<React.PropsWithChildren>(
  ({ children }) => {
    const {
      dataRoomId,
      driverAttestationHash,
      supportedFeatures: { canCreateAudience, enableRuleBasedAudiences },
    } = useMediaDataRoom();
    const { publishedDatasetsHashes, session } = useMediaDataRoomInsightsData();
    const { loading: isDataAttributesLoading } = useDataAttributes({
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes: publishedDatasetsHashes,
      session,
      skip:
        !(canCreateAudience && enableRuleBasedAudiences) ||
        !publishedDatasetsHashes.hasRequiredData,
    });
    const { loading: isLookalikeAudienceStatisticsLoading } =
      useLookalikeAudiencesStatisticPrefetching();
    const { loading: isPublisherAudiencesSizeChecking } =
      usePublisherAudiencesSizePrefetching();
    const contextValue = useMemo<AudiencesDataPrefetchingContextValue>(
      () => ({
        isDataPrefetching:
          isDataAttributesLoading ||
          isLookalikeAudienceStatisticsLoading ||
          isPublisherAudiencesSizeChecking,
      }),
      [
        isDataAttributesLoading,
        isLookalikeAudienceStatisticsLoading,
        isPublisherAudiencesSizeChecking,
      ]
    );
    return (
      <AudiencesDataPrefetchingContext.Provider value={contextValue}>
        {children}
      </AudiencesDataPrefetchingContext.Provider>
    );
  }
);

AudiencesDataPrefetchingWrapper.displayName = "AudiencesDataPrefetchingWrapper";
export default AudiencesDataPrefetchingWrapper;
