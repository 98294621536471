import type JSZip from "jszip";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import {
  MediaDataRoomJobInput,
  type MediaDataRoomJobResultTransform,
  useMediaDataRoomLazyJob,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type LookalikeAudienceStatisticsFileStructure,
} from "features/mediaDataRoom/models";
import { getEffectiveErrorMessage } from "utils";
import { useAudiences } from "../AudiencesWrapper/AudiencesWrapper";

interface LookalikeAudiencesStatisticPrefetchingHookResult {
  loading: boolean;
}

const useLookalikeAudiencesStatisticPrefetching =
  (): LookalikeAudiencesStatisticPrefetchingHookResult => {
    const {
      dataRoomId,
      driverAttestationHash,
      supportedFeatures: {
        canCreateAudience,
        enableLookalike,
        showAbsoluteValues,
        enableExtendedLookalikeStatistics,
      },
    } = useMediaDataRoom();
    const hasStatisticsEnabled = useMemo(
      () => showAbsoluteValues || enableExtendedLookalikeStatistics,
      [showAbsoluteValues, enableExtendedLookalikeStatistics]
    );
    const { publishedDatasetsHashes, session } = useMediaDataRoomInsightsData();
    const { audiences } = useAudiences();
    // Used to store information about the loading state of the lookalike audience statistics
    const [
      lookalikeAudienceStatisticsState,
      setLookalikeAudienceStatisticsState,
    ] = useState<Record<string, { loading?: boolean; error?: Error }>>({});
    // Used to store the IDs of the lookalike audiences that need to be fetched, null means that they are not yet determined
    const [lookalikeAudiencesToFetch, setLookalikeAudiencesToFetch] = useState<
      string[] | null
    >(null);
    const transformLookalikeAudienceStatistics = useCallback<
      MediaDataRoomJobResultTransform<LookalikeAudienceStatisticsFileStructure>
    >(async (zip: JSZip) => {
      const audienceStatisticsFile = zip.file("lookalike_audience.json");
      if (audienceStatisticsFile === null) {
        throw new Error("lookalike_audience.json not found in zip");
      }
      const audienceStatisticsFileStructure = JSON.parse(
        await audienceStatisticsFile.async("string")
      ) as LookalikeAudienceStatisticsFileStructure;
      return audienceStatisticsFileStructure;
    }, []);
    const [getLookalikeAudienceStatistics] = useMediaDataRoomLazyJob({
      input: useMemo(
        () =>
          MediaDataRoomJobInput.create(
            "getLookalikeAudienceStatistics",
            dataRoomId,
            driverAttestationHash,
            publishedDatasetsHashes
          ),
        [dataRoomId, driverAttestationHash, publishedDatasetsHashes]
      ),
      session,
      transform: transformLookalikeAudienceStatistics,
    });
    const handleLookalikeAudienceStatistics = useCallback(
      async (audienceId: string, audiences: Audience[]) => {
        try {
          setLookalikeAudienceStatisticsState((currentState) => ({
            ...currentState,
            [audienceId]: {
              error: undefined,
              loading: true,
            },
          }));
          await getLookalikeAudienceStatistics({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: session!.compiler.abMedia.getParameterPayloads(
                audienceId,
                audiences
              ).generate,
              scopeIdHex,
            }),
            updateInput: (input) => input.withResourceId(audienceId),
            // Lookalike audience statistics computation depends on everything except audiences dataset
            // TODO: Uncomment it when issue with jobs resolved
            // .withoutAudiencesDependency()
          });
          setLookalikeAudienceStatisticsState((currentState) => ({
            ...currentState,
            [audienceId]: {
              error: undefined,
              loading: false,
            },
          }));
        } catch (error) {
          setLookalikeAudienceStatisticsState((currentState) => ({
            ...currentState,
            [audienceId]: {
              error:
                error instanceof Error
                  ? error
                  : new Error(getEffectiveErrorMessage(error)),
              loading: false,
            },
          }));
        }
      },
      [getLookalikeAudienceStatistics, session]
    );
    useEffect(() => {
      if (!enableLookalike || !canCreateAudience || !hasStatisticsEnabled) {
        return;
      }
      setLookalikeAudiencesToFetch((currentState) => {
        // If the required data is not available and the lookalike audiences are already determined, reset the state.
        // This happens when datalab or advertiser data is deprovisioned
        if (!publishedDatasetsHashes.hasRequiredData && currentState?.length) {
          return null;
        }
        // If the lookalike audiences are already determined or there are no audiences, do not change the state
        if (currentState !== null || !audiences.computeResults?.length) {
          return currentState;
        }
        const audiencesToFetch = audiences.computeResults
          ?.filter(
            (audience) =>
              !session!.compiler.abMedia.doesAudienceDependOnLookalikeAudience(
                audience.id,
                audiences.computeResults ?? []
              )
          )
          .map(({ id }) => id);
        // If there are no lookalike audiences to fetch, return an empty array which means we determined that there are no lookalike audiences to fetch
        // and will skip further checks until the datalab or advertiser data is deprovisioned
        if (!audiencesToFetch?.length) {
          return [];
        }
        const nextState = new Set(audiencesToFetch);
        // Totally valid approach to run side effects like this as we are not awaiting data here,
        // its a shorter version of the +1 useEffect that reacts on the change of the state + we have access here to the previous state
        nextState.forEach(
          (audienceId) =>
            void handleLookalikeAudienceStatistics(
              audienceId,
              audiences.computeResults ?? []
            )
        );
        return [...nextState];
      });
    }, [
      enableLookalike,
      canCreateAudience,
      hasStatisticsEnabled,
      audiences,
      session,
      handleLookalikeAudienceStatistics,
      setLookalikeAudiencesToFetch,
      publishedDatasetsHashes.hasRequiredData,
    ]);
    const loading = useMemo(
      () =>
        Object.entries(lookalikeAudienceStatisticsState).some(
          ([id, { loading }]) =>
            lookalikeAudiencesToFetch?.includes(id) && loading
        ),
      [lookalikeAudienceStatisticsState, lookalikeAudiencesToFetch]
    );
    return {
      loading,
    };
  };

export default useLookalikeAudiencesStatisticPrefetching;
