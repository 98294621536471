import {
  type AppState,
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import { Box } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import React, { memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Loading, UnauthorizedDialog } from "components";
import { useConfiguration } from "contexts";
import { EmailNotVerifiedError } from "models";
import { clearDecentriqStorage } from "utils";

const Auth0Wrapper = memo<React.PropsWithChildren>(({ children }) => {
  const { auth0Audience, auth0ClientId, auth0Domain } = useConfiguration();
  const navigate = useNavigate();
  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      navigate(appState?.returnTo || window.location.pathname, {
        replace: true,
      });
    },
    [navigate]
  );
  const authParams = useMemo(
    () => ({
      audience: auth0Audience,
      redirect_uri: window.location.origin,
    }),
    [auth0Audience]
  );
  return (
    <Auth0Provider
      authorizationParams={authParams}
      clientId={auth0ClientId}
      domain={auth0Domain}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
    >
      <Auth0Consumer>{children}</Auth0Consumer>
    </Auth0Provider>
  );
});
Auth0Wrapper.displayName = "Auth0Wrapper";

interface Auth0ErrorProps {
  error: Error;
}

const Auth0Error = memo<Auth0ErrorProps>(({ error }) => {
  const { logout } = useAuth0();
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <UnauthorizedDialog
        error={error}
        onClose={useCallback(() => {
          clearDecentriqStorage();
          void logout({ logoutParams: { returnTo: window.location.origin } });
        }, [logout])}
        open={true}
      />
    </Box>
  );
});
Auth0Error.displayName = "Auth0Error";

const Auth0Consumer = withAuthenticationRequired(
  memo<React.PropsWithChildren>(({ children }) => {
    const { error, isLoading, getIdTokenClaims, isAuthenticated } = useAuth0();

    const { data: isEmailVerified, isLoading: isEmailVerifiedLoading } =
      useQuery({
        queryFn: async () => {
          if (!isAuthenticated) {
            return null;
          }
          const token = await getIdTokenClaims();
          return token?.email_verified;
        },
        queryKey: ["auth", "isEmailVerified", isAuthenticated],
      });

    if (error) {
      return <Auth0Error error={error} />;
    }

    if (isEmailVerified === false) {
      return (
        <Auth0Error error={new EmailNotVerifiedError("Email not verified")} />
      );
    }

    if (isLoading || isEmailVerifiedLoading) {
      return <Loading />;
    }

    return children;
  }),
  {
    loginOptions: {
      authorizationParams: {
        screen_hint: window.location.pathname.includes("/signup")
          ? "signup"
          : undefined,
      },
    },
  }
);
Auth0Consumer.displayName = "Auth0Consumer";

export default Auth0Wrapper;
