import { type Session } from "@decentriq/core";
import { usePublishedMediaInsightsDcrDataLabDataQuery } from "@decentriq/graphql/dist/hooks";
import { type FullDataLabFragment } from "@decentriq/graphql/dist/types";
import { useQuery } from "@tanstack/react-query";
import { createContext, memo, useMemo } from "react";
import { useApiCore } from "contexts";
import {
  type MediaDataRoomJobHookResult,
  MediaDataRoomJobInput,
} from "features/mediaDataRoom/hooks";
import { useSafeContext } from "hooks";
import { useMediaDataRoom } from "../MediaDataRoomContext/MediaDataRoomContext";
import useAdvertiserDataReport from "./useAdvertiserDataReport";
import useDatasets, { type DatasetsHookResult } from "./useDatasets";
import useInsights, { type InsightsHookResult } from "./useInsights";
import useOverlapStatistics, {
  type OverlapStatisticsHookResult,
} from "./useOverlapStatistics";

export interface MediaDataRoomInsightsDataContextValue {
  datasets: DatasetsHookResult;
  publishedDatasetsHashes: DatasetsHookResult["data"];
  isPublisherAudienceBeingUpdated: boolean;
  insights: InsightsHookResult;
  overlapStatistics: OverlapStatisticsHookResult;
  advertiserDataReport: MediaDataRoomJobHookResult<{
    numberOfDeduplicatedRows: number;
    numberOfIngestedRows: number;
  }>;
  // TODO: discuss if such caching is really necessary
  session?: Session;
  publishedDataLab: FullDataLabFragment | null;
  dataLabDataLoading: boolean;
}

export const MediaDataRoomInsightsDataContext =
  createContext<MediaDataRoomInsightsDataContextValue | null>(null);
MediaDataRoomInsightsDataContext.displayName =
  "MediaDataRoomInsightsDataContext";

export const useMediaDataRoomInsightsData = () =>
  useSafeContext(MediaDataRoomInsightsDataContext);

export type MediaDataRoomInsightsDataWrapperProps = React.PropsWithChildren;

const MediaDataRoomInsightsDataWrapper =
  memo<MediaDataRoomInsightsDataWrapperProps>(({ children }) => {
    const {
      dataRoomId,
      driverAttestationHash,
      isDeactivated,
      isAdvertiser,
      isDataPartner,
      isPublisher,
      hasDataPartner,
      supportedFeatures: { enableInsights },
    } = useMediaDataRoom();
    const { sessionManager } = useApiCore();
    const {
      data: dataLabData,
      loading: dataLabDataLoading,
      refetch: refetchDatalabQuery,
    } = usePublishedMediaInsightsDcrDataLabDataQuery({
      skip: !isPublisher,
      variables: {
        id: dataRoomId,
      },
    });
    const { data: session } = useQuery({
      enabled: driverAttestationHash != null,
      queryFn: async () => {
        return await sessionManager.get({ driverAttestationHash });
      },
      queryKey: [
        ...MediaDataRoomJobInput.buildQueryPrefix({
          dataRoomId,
          driverAttestationHash,
        }),
        "session",
        "fetchResults",
      ],
    });
    const datasets = useDatasets({
      dataRoomId,
      driverAttestationHash,
      refetchDataLab: isPublisher ? void refetchDatalabQuery : undefined,
      session,
    });
    const insights = useInsights({
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes: datasets.data,
      session,
      skip:
        isDeactivated ||
        !datasets.data.hasRequiredData ||
        !enableInsights ||
        isDataPartner,
    });
    const overlapStatistics = useOverlapStatistics({
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes: datasets.data,
      session,
      skip: isDeactivated || !datasets.data.hasRequiredData,
    });
    const advertiserDataReport = useAdvertiserDataReport({
      dataRoomId,
      driverAttestationHash,
      publishedDatasetsHashes: datasets.data,
      session,
      skip:
        isDeactivated ||
        !(hasDataPartner ? isDataPartner : isAdvertiser) ||
        !datasets.data.hasAdvertiserData,
    });
    const contextValue = useMemo<MediaDataRoomInsightsDataContextValue>(
      () => ({
        advertiserDataReport,
        dataLabDataLoading,
        datasets,
        datasetsData: datasets.data,
        insights,
        isPublisherAudienceBeingUpdated:
          Boolean(
            dataLabData?.publishedMediaInsightsDcr?.wasDataLabPublishedBefore
          ) &&
          !datasets.data.hasPublisherData &&
          Boolean(datasets.data.hasAdvertiserData),
        overlapStatistics,
        publishedDataLab:
          dataLabData?.publishedMediaInsightsDcr?.publishedDataLab ?? null,
        publishedDatasetsHashes: datasets.data,
        session,
      }),
      [
        datasets,
        advertiserDataReport,
        insights,
        overlapStatistics,
        dataLabData?.publishedMediaInsightsDcr?.publishedDataLab,
        dataLabData?.publishedMediaInsightsDcr?.wasDataLabPublishedBefore,
        session,
        dataLabDataLoading,
      ]
    );
    return (
      <MediaDataRoomInsightsDataContext.Provider value={contextValue}>
        {children}
      </MediaDataRoomInsightsDataContext.Provider>
    );
  });

MediaDataRoomInsightsDataWrapper.displayName =
  "MediaDataRoomInsightsDataWrapper";

export default MediaDataRoomInsightsDataWrapper;
