import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from "@apollo/client";
import { memo, useEffect, useMemo, useRef } from "react";
import {
  ClientSideKeychain,
  EnclaveKeychain,
  type Keychain,
} from "services/keychain";
import { KeychainProvider, useApiCore } from "contexts";
import KeychainSetupWrapper, {
  defaultKeychainSetupContextValue,
  type KeychainSetupContextValue,
  KeychainSetupProvider,
  KeychainStatus,
} from "../KeychainSetupWrapper/KeychainSetupWrapper";

export const useKeychainSharedInstance = () => {
  const clientRef = useRef<Keychain | null>(null);
  return useMemo<[() => Keychain | null, (client: Keychain) => void]>(
    () => [
      () => clientRef.current,
      (client: Keychain) => {
        clientRef.current = client;
      },
    ],
    [clientRef]
  );
};

const KeychainWrapper = memo<
  React.PropsWithChildren & {
    onKeychainInstanceUpdated: (keychain: Keychain) => void;
  }
>(({ children, onKeychainInstanceUpdated }) => {
  const { client, isMigrated, getSessionV2 } = useApiCore();
  const apolloClient = useApolloClient();
  const keychain = useMemo(
    () =>
      isMigrated
        ? new EnclaveKeychain(
            client.userEmail,
            client,
            apolloClient as ApolloClient<NormalizedCacheObject>,
            getSessionV2
          )
        : new ClientSideKeychain(client),
    /// By the time this wrapper is rendered all of the args for creation are established so its safe to just
    /// rely on `isMigrated`
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMigrated]
  );
  useEffect(() => {
    keychain.statusOrInitialize();
  }, [keychain]);
  useEffect(() => {
    onKeychainInstanceUpdated(keychain);
  }, [onKeychainInstanceUpdated, keychain]);
  const keychainSetupContextValue = useMemo<KeychainSetupContextValue>(
    () => ({
      ...defaultKeychainSetupContextValue,
      status: KeychainStatus.unlocked,
    }),
    []
  );
  return (
    <KeychainProvider value={keychain}>
      {keychain instanceof ClientSideKeychain ? (
        <KeychainSetupWrapper>{children}</KeychainSetupWrapper>
      ) : (
        <KeychainSetupProvider value={keychainSetupContextValue}>
          {children}
        </KeychainSetupProvider>
      )}
    </KeychainProvider>
  );
});

KeychainWrapper.displayName = "KeychainServiceWrapper";

export default KeychainWrapper;
