import { DqCard, DqCardContent } from "@decentriq/components";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Stack, Typography } from "@mui/joy";
import { useMount } from "ahooks";
import { Fragment, memo } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom";
import {
  EstimatedAudienceSize,
  QualityScore,
  ReachTradeoff,
} from "features/mediaDataRoom/components";
import { useLookalikeAudienceStatistics } from "features/mediaDataRoom/hooks";
import {
  type Audience,
  audienceTypePresentationMap,
} from "features/mediaDataRoom/models";
import { useAudiences } from "features/mediaDataRoom/wrappers";
import AudienceCreationDate from "../AudienceCreationDate/AudienceCreationDate";
import AudienceName from "../AudienceName/AudienceName";
import AudienceStatusLabel from "../AudienceStatusLabel/AudienceStatusLabel";
import AudienceDrawerRuleBasedDetails from "./AudienceDrawerRuleBasedDetails";

type AudienceDrawerDetailsProps = {
  seedAudience: Audience | null;
} & Audience;

const AudienceDrawerDetails = memo<AudienceDrawerDetailsProps>((audience) => {
  const {
    id,
    kind,
    mutable: { status, name, created_at },
    reach,
    exclude_seed_audience,
    seedAudience,
  } = audience;
  const {
    supportedFeatures: { showAbsoluteValues },
    isPublisher,
  } = useMediaDataRoom();
  const {
    audiences,
    audienceSizes: { enableSizeEstimationForAudience, audienceSizes },
  } = useAudiences();
  const {
    audienceQualityScore,
    computeState: { loading: isLookalikeAudienceStatisticsLoading },
  } = useLookalikeAudienceStatistics({
    audienceId: id,
    audienceReach: reach as number,
    audiences: audiences.computeResults || [],
    skip: kind !== "lookalike",
  });
  useMount(() => {
    if (kind !== "advertiser") {
      enableSizeEstimationForAudience(id);
    }
  });
  return (
    <Stack gap={1}>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Stack spacing={0.5}>
            <Typography
              level="body-sm"
              startDecorator={<b>Type:</b>}
              textColor="inherit"
            >
              {audienceTypePresentationMap.get(kind) || "N/A"}
            </Typography>
            <Typography
              level="body-sm"
              startDecorator={<b>Creation date:</b>}
              textColor="inherit"
            >
              <AudienceCreationDate createdAt={created_at} />
            </Typography>
            {kind === "lookalike" && seedAudience ? (
              <Fragment>
                <Typography
                  level="body-sm"
                  startDecorator={<b>Audience used as seed:</b>}
                  textColor="inherit"
                >
                  <AudienceName
                    kind={seedAudience.kind}
                    name={seedAudience.mutable.name}
                  />
                </Typography>
                <Typography
                  level="body-sm"
                  startDecorator={<b>Seed audience:</b>}
                  textColor="inherit"
                >
                  {exclude_seed_audience ? "Excluded" : "Included"}
                </Typography>
              </Fragment>
            ) : null}
            {!isPublisher && (
              <Typography
                level="body-sm"
                startDecorator={<b>Status:</b>}
                textColor="inherit"
              >
                <AudienceStatusLabel id={id} status={status} />
              </Typography>
            )}
          </Stack>
        </DqCardContent>
      </DqCard>
      {kind === "rulebased" && <AudienceDrawerRuleBasedDetails {...audience} />}
      {showAbsoluteValues && (
        <EstimatedAudienceSize
          estimatedAudienceSize={
            kind === "advertiser"
              ? audience.audience_size
              : audienceSizes[id]?.audienceSize
          }
          loading={audienceSizes[id]?.loading}
        />
      )}
      {kind === "lookalike" && (
        <>
          <ReachTradeoff reach={reach} />
          <QualityScore
            loading={isLookalikeAudienceStatisticsLoading}
            qualityScore={audienceQualityScore}
          />
        </>
      )}
      <Alert>
        {kind === "advertiser"
          ? `This audience has been automatically generated by matching all users from ${name} with the publisher audience.`
          : "You can't edit this audience directly because it has already been finalized"}
      </Alert>
    </Stack>
  );
});

AudienceDrawerDetails.displayName = "AudienceDrawerDetails";
export default AudienceDrawerDetails;
