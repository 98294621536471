import { type ApolloClient, type NormalizedCacheObject } from "@apollo/client";
import {
  type Client,
  type Secret,
  type SessionV2,
  utils,
} from "@decentriq/core";
import {
  DataConnectorJobSecretDocument,
  DataConnectorJobsSecretsDocument,
  DatasetSecretsByHashDocument,
  DatasetsSecretsDocument,
} from "@decentriq/graphql/dist/hooks";
import {
  type DataConnectorJobSecretQuery,
  type DataConnectorJobsSecretsQuery,
  type DatasetSecretsByHashQuery,
  type DatasetsSecretsQuery,
} from "@decentriq/graphql/dist/types";
import { type secret_store as ddcSecretStore } from "ddc";
import * as forge from "node-forge";
import { logError } from "utils";
import {
  type Keychain,
  type KeychainItem,
  KeychainItemKind,
  UserKeychainStatus,
} from "./types";

type ListenerCallback = (() => void) | (() => Promise<void>);
type DatasetWithSecrets = DatasetsSecretsQuery["datasets"]["nodes"][number];
type DataConnectorJobWithSecrets =
  DataConnectorJobsSecretsQuery["dataConnectorJobs"]["nodes"][number];

interface DatasetSecrets {
  encryptionKeySecret?: string | null;
  metadataSecret?: string | null;
  importExportSecret?: string | null;
}

export class KeychainItemWithAcl implements KeychainItem {
  constructor(
    public id: string,
    public kind: KeychainItemKind,
    public value: string,
    public secretId: string,
    public acl: ddcSecretStore.SecretStoreEntryState["acl"]["users"],
    public casIndex: number
  ) {}

  public isUserOwner(userEmail: string): boolean {
    return this.acl.some(
      ({ id, role }) => id === userEmail && role === "Owner"
    );
  }

  public isUserOwnerOrUser(userEmail: string): boolean {
    return this.acl.some(({ id }) => id === userEmail);
  }

  get hasMultipleOwners(): boolean {
    return this.acl.filter(({ role }) => role === "Owner").length > 1;
  }
}

export class EnclaveKeychain implements Keychain {
  private listeners: Set<ListenerCallback>;

  constructor(
    private userId: string,
    private client: Client,
    private apolloClient: ApolloClient<NormalizedCacheObject>,
    private getSession: () => Promise<SessionV2>
  ) {
    this.listeners = new Set();
  }

  async insertItem(item: KeychainItem): Promise<KeychainItem> {
    const session = await this.getSession();
    const [result] = await this.insertItemForSession(item, session);
    this.notifyListeners();
    return result;
  }

  async insertItems(items: KeychainItem[]): Promise<KeychainItem[]> {
    const session = await this.getSession();
    const result = await Promise.all(
      items.map((item) =>
        this.insertItemForSession(item, session).then(([result]) => result)
      )
    );
    this.notifyListeners();
    return result;
  }

  async removeItem(item: Omit<KeychainItem, "value">): Promise<boolean> {
    this.notifyListeners();
    return true;
  }

  async removeItems(items: Omit<KeychainItem, "value">[]): Promise<void> {
    this.notifyListeners();
    return;
  }

  async getItem(
    id: string,
    kind: KeychainItemKind
  ): Promise<KeychainItemWithAcl> {
    const session = await this.getSession();
    return this.getItemForSession(id, kind, session);
  }

  async getItems(): Promise<KeychainItemWithAcl[]> {
    const session = await this.getSession();
    return this.getItemsForSession(session);
  }

  async statusOrInitialize(): Promise<UserKeychainStatus> {
    return (await this.client.getMigrationInfo())?.migrationCompletedAt
      ? UserKeychainStatus.Unlocked
      : UserKeychainStatus.Locked;
  }

  registerListener(callback: ListenerCallback): () => void {
    this.listeners.add(callback);
    return () => this.listeners.delete(callback);
  }

  private notifyListeners() {
    for (const callback of this.listeners) {
      callback();
    }
  }

  private async insertItemForSession(
    item: KeychainItem,
    session: SessionV2
  ): Promise<[KeychainItem, string]> {
    const secret = this.mapKeychainItemToSecret(item);
    const secretId = await session.createSecret(secret);
    return [item, secretId];
  }

  private async getItemForSession(
    id: string,
    kind: KeychainItemKind,
    session: SessionV2
  ): Promise<KeychainItemWithAcl> {
    const secrets = await this.getDatasetSecrets(id, kind);
    let secretId: string | null | undefined;
    switch (kind) {
      case KeychainItemKind.DatasetMetadata:
        secretId = secrets.metadataSecret;
        break;
      case KeychainItemKind.Dataset:
        secretId = secrets.encryptionKeySecret;
        break;
      case KeychainItemKind.PendingDatasetImport:
        secretId = secrets.importExportSecret;
        break;
      default:
        throw Error("Unsupported keychain item kind");
    }
    if (!secretId) {
      throw new Error(`Invalid Keychain id: ${id} with kind: ${kind}`);
    }
    const [secret, casIndex] = await session.getSecret(secretId);
    return this.mapSecretToKeychainItemWithAcl(secret, secretId, casIndex);
  }

  private async getItemsForSession(
    session: SessionV2
  ): Promise<KeychainItemWithAcl[]> {
    const secrets = await this.getDatasetsSecrets();
    return await Promise.all(
      secrets
        .flatMap((secrets) => Object.values(secrets).filter(Boolean))
        .map((id) =>
          session
            .getSecret(id)
            .then(([secret, casIndex]) =>
              this.mapSecretToKeychainItemWithAcl(secret, id, casIndex)
            )
        )
    );
  }

  private mapKeychainItemToSecret(item: KeychainItem): Secret {
    switch (item.kind) {
      case KeychainItemKind.Dataset:
        return utils.keychainEntryToSecret(
          {
            key: item.id,
            kind: "dataset_key",
            value: forge.util.binary.hex.decode(item.value),
          },
          this.userId
        );
      case KeychainItemKind.DatasetMetadata:
        return utils.keychainEntryToSecret(
          {
            key: item.id,
            kind: "dataset_metadata",
            value: new TextEncoder().encode(item.value),
          },
          this.userId
        );
      case KeychainItemKind.PendingDatasetImport:
        return utils.keychainEntryToSecret(
          {
            key: item.id,
            kind: "pending_dataset_import",
            value: forge.util.binary.hex.decode(item.value),
          },
          this.userId
        );
      default:
        throw new Error(`Unsupported KeychainItem kind: ${item.kind}`);
    }
  }

  private mapSecretToKeychainItemWithAcl(
    secret: Secret,
    secretId: string,
    casIndex: number
  ): KeychainItemWithAcl {
    switch (secret.state.type) {
      case "DatasetKey":
        return new KeychainItemWithAcl(
          secret.state.manifest_hash!,
          KeychainItemKind.Dataset,
          forge.util.binary.hex.encode(secret.data),
          secretId,
          secret.state.acl.users,
          casIndex
        );
      case "DatasetMetadata":
        return new KeychainItemWithAcl(
          secret.state.manifest_hash!,
          KeychainItemKind.DatasetMetadata,
          new TextDecoder().decode(secret.data),
          secretId,
          secret.state.acl.users,
          casIndex
        );
      case "PendingDatasetImport":
        return new KeychainItemWithAcl(
          secret.state.data_connector_job_id!,
          KeychainItemKind.PendingDatasetImport,
          forge.util.binary.hex.encode(secret.data),
          secretId,
          secret.state.acl.users,
          casIndex
        );
      default:
        throw new Error(`Unsupported Secret type: ${secret.state.type}`);
    }
  }

  private mapDatasetWithSecretsToDatasetSecrets(
    dataset: DatasetWithSecrets | undefined | null
  ): DatasetSecrets {
    return {
      encryptionKeySecret: dataset?.encryptionKeySecretId,
      metadataSecret: dataset?.metadataSecretId,
    };
  }

  private mapDataConnectorJobWithSecretsToDatasetSecrets(
    dataConnectorJob: DataConnectorJobWithSecrets | undefined | null
  ): DatasetSecrets {
    return {
      importExportSecret: dataConnectorJob?.secretId,
    };
  }

  private async getDatasetSecrets(
    id: string,
    kind: KeychainItemKind
  ): Promise<DatasetSecrets> {
    if (kind === KeychainItemKind.PendingDatasetImport) {
      try {
        const { data } =
          await this.apolloClient.query<DataConnectorJobSecretQuery>({
            query: DataConnectorJobSecretDocument,
            variables: {
              id,
            },
          });
        return this.mapDataConnectorJobWithSecretsToDatasetSecrets(
          data?.dataConnectorJob
        );
      } catch (error) {
        logError("Unable to get dataset secrets: ", error);
        return {};
      }
    }
    try {
      const { data } = await this.apolloClient.query<DatasetSecretsByHashQuery>(
        {
          query: DatasetSecretsByHashDocument,
          variables: {
            manifestHash: id,
          },
        }
      );
      return this.mapDatasetWithSecretsToDatasetSecrets(
        data?.datasetByManifestHash
      );
    } catch (error) {
      logError("Unable to get dataset secrets: ", error);
      return {};
    }
  }

  private async getDatasetsSecrets(): Promise<DatasetSecrets[]> {
    const [importSecrets, datasetSecrets] = await Promise.all([
      (async () => {
        try {
          const { data: datasetsData } =
            await this.apolloClient.query<DataConnectorJobsSecretsQuery>({
              fetchPolicy: "network-only",
              query: DataConnectorJobsSecretsDocument,
            });
          return (
            datasetsData?.dataConnectorJobs?.nodes?.map(
              this.mapDataConnectorJobWithSecretsToDatasetSecrets
            ) ?? []
          );
        } catch (error) {
          logError("Unable to get datasets secrets: ", error);
          return [];
        }
      })(),
      (async () => {
        try {
          const { data: datasetsData } =
            await this.apolloClient.query<DatasetsSecretsQuery>({
              fetchPolicy: "network-only",
              query: DatasetsSecretsDocument,
            });
          return (
            datasetsData?.datasets?.nodes?.map(
              this.mapDatasetWithSecretsToDatasetSecrets
            ) ?? []
          );
        } catch (error) {
          logError("Unable to get datasets secrets: ", error);
          return [];
        }
      })(),
    ]);
    return [...importSecrets, ...datasetSecrets];
  }
}
