import { useEffect, useMemo, useState } from "react";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import { useAudiences } from "../AudiencesWrapper/AudiencesWrapper";

interface PublisherAudiencesSizePrefetchingHookResult {
  loading: boolean;
}

const usePublisherAudiencesSizePrefetching =
  (): PublisherAudiencesSizePrefetchingHookResult => {
    const {
      isPublisher,
      supportedFeatures: { showAbsoluteValues },
    } = useMediaDataRoom();
    const { publishedDatasetsHashes, session } = useMediaDataRoomInsightsData();
    const {
      audiences,
      audienceSizes: { enableSizeEstimationForAudience, audienceSizes },
    } = useAudiences();
    // Used to store the IDs of the publisher audiences for which we need to check size, null means that they are not yet determined
    const [publisherAudiencesToCheckSize, setPublisherAudiencesToCheckSize] =
      useState<string[] | null>(null);
    useEffect(() => {
      if (!isPublisher || !showAbsoluteValues) {
        return;
      }
      setPublisherAudiencesToCheckSize((currentState) => {
        // If the required data is not available and the audiences are already determined, reset the state.
        // This happens when datalab or advertiser data is deprovisioned
        if (!publishedDatasetsHashes.hasRequiredData && currentState?.length) {
          return null;
        }
        // If the audiences are already determined or there are no audiences, do not change the state
        if (currentState !== null || !audiences.computeResults?.length) {
          return currentState;
        }
        const audiencesToFetch = audiences.computeResults
          ?.filter((audience) => audience.kind !== "advertiser")
          .map(({ id }) => id);
        // If there are no audiences for which we need to check size, return an empty array which means we determined that there are no audiences to check
        // and will skip further checks until the datalab or advertiser data is deprovisioned
        if (!audiencesToFetch?.length) {
          return [];
        }
        const nextState = new Set(audiencesToFetch);
        // Totally valid approach to run side effects like this as we are not awaiting data here,
        // its a shorter version of the +1 useEffect that reacts on the change of the state + we have access here to the previous state
        nextState.forEach(enableSizeEstimationForAudience);
        return [...nextState];
      });
    }, [
      isPublisher,
      showAbsoluteValues,
      audiences,
      session,
      enableSizeEstimationForAudience,
      setPublisherAudiencesToCheckSize,
      publishedDatasetsHashes.hasRequiredData,
    ]);
    const loading = useMemo(
      () =>
        Object.entries(audienceSizes).some(
          ([id, { loading }]) =>
            publisherAudiencesToCheckSize?.includes(id) && loading
        ),
      [audienceSizes, publisherAudiencesToCheckSize]
    );
    return {
      loading,
    };
  };

export default usePublisherAudiencesSizePrefetching;
